@import '../bootstrap/dist/css/bootstrap.min.css';
@import '../font-awesome/css/font-awesome.min.css';
@import '../assets/css/atlantis.css';
@import '../assets/css/atlantis.min.css';
@import '../assets/css/demo.css';
@import '../assets/css/fonts.css';
@import '../assets/css/fonts.min.css';
@font-face {
 font-family: "Maison Neue Bold";
 src: url('../fonts/Maison-Neue-Bold.eot?') format('eot'),
 url("../fonts/Maison-Neue-Bold.ttf") format('truetype'),
 url("../fonts/Maison-Neue-Bold.woff") format("woff"),
 url("../fonts/Maison-Neue-Bold.woff2") format("woff2");
 font-style: normal;
}
@font-face {
 font-family: "Maison Neue Mono";
 src: url('../fonts/Maison-Neue-mono.eot?') format('eot'),
 url("../fonts/Maison-Neue-mono.ttf") format('truetype'),
 url("../fonts/Maison-Neue-mono.woff") format("woff"),
 url("../fonts/Maison-Neue-mono.woff2") format("woff2");
 font-style: normal;
}
body {
  font-family: Maison Neue Mono;
  font-style: bold!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-header-color {
  background: #1E2431!important;
  height: 32px!important; 
  min-height: 65px!important;
  margin: 1.5% 0 0 0; 
}
.main-header-top-color {
  background: #FFF!important;
  height: 32px!important; 
  min-height: 25px;
}
/*Debut du positionnement du dropdown du top header*/
.main-header-top-color ul {
    line-height: 1px;
}
.main-header-top-color .navbar-header {
  min-height: 2px!important;
}
.main-header-top-color .css-2b097c-container{
  color: black!important;
  border: 1px solid #FFF!important;
  border-radius: 5px;
}
.main-header-top-color .css-1wa3eu0-placeholder {
  position: relative!important;
}
.main-header-top-color .css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  align-self: stretch;
  /*background-color: hsl(0,0%,80%);*/
  background-color: #FFF;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}
.main-header-top-color .css-g1d714-ValueContainer {
  padding: 0px !important;
}
.main-header-top-color .css-1uccc91-singleValue {
  overflow: initial;
}
.main-header-top-color .css-1pahdxg-control {
  border-color: #FFF!important;
  box-shadow: 0 0 0 1px #FFF!important;
}
.main-header-top-color .css-1pahdxg-control:hover {
  border-color: #FFF!important;
}
.main-header-top-color .css-1pahdxg-control {
  max-height: 25px!important;
  min-height: 20px!important;
  background-color: transparent;
}
.main-header-top-color .css-yk16xz-control {
  max-height: 25px!important;
  min-height: 20px!important;
  background-color: transparent;
  margin: -3px 0 0 0!important;
}

/*Fin du positionnement du dropdown du top header*/
.logo-header-width {
  width: 300px!important;
  margin: 3px;
}
.main-header-color .logo-header-width {
  width: 300px!important;
  margin: 13px 0 0 0!important;
}
.main-header-color .navbar-header {
  margin: 7px 0 0 0!important;
}
.logo-header-top-width {
  width: 300px!important;
}
.logo {
font-family: Maison Neue Bold!important;
left: .3vw;
top: .7vh;
font-size: 28px;
line-height: 34px;
color: #FFFFFF!important;
}
.main-header-top-color .logo-header .logo {
  height: 118%!important;
}
.logo_header_size {
  width: 2.5vw;
}
.icon_header_size {
  width: 2.5vw;
  padding: 0 50% 0 0!important;
}
.text_header_size {
  margin: 0 1px;
  font-size: 18px; 
}
.body_l_t {
  background-color: #E1FFF3;
  margin: 4.5% 0 0 0;
  background: linear-gradient(50deg, #E1FFF3, #42a34f);
  background-blend-mode: color, normal;
  z-index: 2
}
.h-100, .h-100 .row:first-child {
  height: 100%!important;
}
.acide_left_image_position {
  position: absolute;
  top: 14vh;
  left: 7%;
  width: 3.5vw;
}
.text_a_l_i {
  font-family: Maison Neue Bold!important;
  position: absolute;
  top: 16vh;
  left: 18%;
  font-size: 28px;
  line-height: 34px;
  color: #42a350;
}
.text_a_l_under_i {
  font-family: Maison Neue Bold!important;
  position: absolute;
  top: 30vh;
    left: 7%;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  color: #1E2431;
}
.text_a_l_under_i_2 {
  position: absolute;
  top: 38vh;
    left: 7%;
  font-size: 18px;
  line-height: 28px;
  width: 80%;
  font-weight: 500;
  color: #1E2431;
}
.rejoignez_nous {
  position: absolute;
  top: 54vh;
  left: 7%;
  width: 54px;
  height: 54px;
}
.text_rejoignez_n {
  font-family: Maison Neue Bold!important;
  position: absolute;
  top: 57vh;
  left: 18%;
  font-size: 18px;
  line-height: 18px;
  color: #1E2431;
}
.img_background {
  position: absolute;
  left: 1%;
  right: 53.07%;
  /*top: 4%;*/
  bottom: .5%;
  width: 60%;
  z-index: 1;
  opacity: 0.2;
}
.img_background1 {
  position: absolute;
  left: 0.01%;
  right: 0%;
  top: 24.23%;
  bottom: -50%;
  background: rgba(30, 36, 49, 0.05);
  transform: rotate(-30deg);
}
.sidebar, .sidebar[data-background-color=white] {
  margin-top: 72px!important;
}
.sidebar-style-lud i:after{
  color: transparent!important;
}
.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #54A649!important;
}
.sidebar .nav.nav-primary>.nav-item a:focus i {
    color: #8d9498!important;
}
.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
    background: #54A649!important;
}
.sidebar .nav-collapse li a, .sidebar[data-background-color=white] .nav-collapse li a {
    padding: 1px 25px!important;
}
.sidebar .nav-collapse, .sidebar[data-background-color=white] .nav-collapse {
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-top: 1px!important;
}
.table {
  width: 100%;
}
.table thead th {
  font-weight: 500!important;
}
.th_t th:last-child {
  text-align: center
}
.css-2b097c-container{
  color: black!important;
  border: 1px solid #ced4da!important;
  border-radius: 5px;
}
.row_scroll_ok_t .css-2b097c-container {
  width: 8vw!important;
  display: inline-block!important;
  margin: 0 3% 0 3%!important;
}
.table_t .nav-search .input-group {
  border: 1px solid #dcdbdb!important;
  background: #ffffff!important;
  border-radius: 30px!important;
}
.table_t .class_search_t {
    padding: 12px 0 8px 10px;
    border: none!important;
    /*border-radius: 15px;*/
    width: 80%;
    height: 100%;
}
.home_t > .main-panel {
  background: #43a34f2b;
}
.datatables_t .ajust_row {
  align-items: baseline!important;
  margin-right: 15px!important;
  margin-left: 15px!important;
}
.margin_pag_t {
  text-align: right;
}
.header-cell {
  font-weight: 600!important;
}
.admin_p_t .sweet-alert {
  width: 62vw!important;
}
.input_t {
  display: block;
  width: 100%;
  padding: .69rem 1rem!important;
  border: 1px solid #ced4da!important;
  font-size: 14px!important;
  font-weight: 400;
  line-height: 1.3;
  border-radius: .25rem;
  height: inherit!important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.css-g1d714-ValueContainer {
  padding: .5rem 1rem;
}
.amin_pays .css-g1d714-ValueContainer {
  padding: 0px!important;
}
.css-yk16xz-control {
  border-style: hidden!important;
  border-radius: 60px!important;
}
ul {
  list-style-type: none;
  line-height: 35px;
}
.c_admin_pays_ul {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid green;
  border-radius: 10px;
  margin-block-start: 1em!important;
  margin-block-end: 1em!important;
  margin-inline-start: 0px!important;
  margin-inline-end: 30px!important;
  /*padding-inline-start: 20px!important;*/
  padding: 10px 60px 10px 20px!important;
  z-index: 2;
}
.form-row_2_t {
  margin: 20px 0 0 0;
}
.params_min_height {
  min-height: 120px
}
.actif {
  color: #54A649!important;
}
a {
  text-decoration: none!important;
}
fieldset {
  width: 100%;
  border: 1px solid #57596240 !important;
  padding: 0 .3em 1.4em .7em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow:  0px 0px 0px 0px #000;
  box-shadow:  0px 0px 0px 0px #000;
}
legend {
  font-size: 1em !important;
  font-weight: bold !important;
  text-align: left !important;
  width:auto;
  padding:0 10px;
  border-bottom:none;
}
label.field {
  text-align: left;
  width: 100px;
  float: left;
  font-weight: bold;
  color: red;
}
.btn_direction_t {
  background: #FFF!important;
  color: #54A649!important;
  border: .5px solid #54A649;
  border-radius: 25px;
  margin: 20px 15px 0 0;
  box-shadow: 0px 0px 2px 2px #54a64933;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
a.disabled_t {
  opacity: .65;
  /*pointer-events: none;*/
}
a.disabled_t:hover {
  cursor: not-allowed!important;
}
/*.css-2b097c-container2_t {
  position: relative!important;
  margin: 0 0 20vh 0!important;
  z-index: 5601!important;
}*/
.css-2613qy-menu {
  position: relative!important;
  margin: 0 0 50% 0!important;
  color:black;
  display: none!important;
  background-color: red!important;
  z-index: 5601
}
.admin_p_t .error_t span {
  font-size: 11px
}
.react-tel-input .form-control {
  width: 100%!important;
  line-height: 21px!important;
}
.css-1uccc91-singleValue {
  font-size: 13px!important;
}
.pwd_reinit {
  padding: 3px 10px 3px 10px!important; 
  border-radius: 5px;
}

/*Debut de laPartie qui fait en sorte 
que menu de selections survole la modale*/
.sweet-alert {
  overflow: initial!important;
}
/*Fin de laPartie qui fait en sorte 
que menu de selections survole la modale*/
.class_bnt_t {
  padding: .4rem 1.4rem!important;
  border-radius: 7px!important;
}
.error_Compte_P {
  border-top: 1px solid #cececece;
  border-right: 1px solid #cececece;
  border-bottom: 1px solid #cececece;
  border-left: 4px solid red;
  padding: 3% 0 3% 0%;
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 600;
  background-color: #ffc0cb33;
}
.forgot_pwd_text {
  width: 70%;
  text-align: center;
  margin: 10% 0 12% 5%;
}
.eyeshow {
    display: block;
}
.eyehide {
  display: none;
}
.eye-password svg {
    width: 20px;
    position: absolute;
    right: 25px;
    bottom: 27%;
    color: #868686;
    fill: #868686;
}
svg:not(:root) {
    overflow: hidden;
}
a > svg {
    transition: all .15s ease;
}
.bnt_and_load_t {
    position: absolute;
    left: 40%;
    font-size: 17px;
    margin: -2px 0 0 0;
}
.cancel_btn_t {
  position: absolute;
  top: 5px;
  right: 10px;
}
.login_t .form-check, .form-group {
    margin-bottom: 0;
    padding: 30px 0 0 0!important;
}
.login_t .eye-password svg {
    width: 20px;
    position: absolute;
    right: 25px;
    bottom: 15%!important;
    color: #868686;
    fill: #868686;
}
.text-right {
  padding: 1.4rem;
}
.pad_rmd_me {
  padding: 0 0 0 2.6rem
}
.img_acide_left_t .img1_1 {
  width: 12%;
  margin: 0 8% 0 0;
}
.img_acide_left_t .img2_1 {
  width: 10%;
  margin: 0 8% 0 0;
}
.admin_p_r .sweet-alert {
  width: 42em!important;
} 
.admin_p_r .form-row {
  margin-bottom: 4vh;
}
/*Debut Modal Ville*/
.ville_t .form-check, .form-group {
    margin-bottom: 0;
    padding: 30px;
}

/*End Modal Ville*/
.show_t {
  display: block;
}
.hidden_t {
  display: none;
}
.div_load_t {
  position: absolute;
  margin: -23px 0 0 -23px;
}
.sweet-alert > p {
  z-index: 0!important;
}
.font_size_label_t {
  font-size: 12px!important;
}
.shop_list_t {
  margin: 20px 0 0 0;
}
.shopt_list_manager a {
  color: #495057;
}
.shop_list_name_t {
  font-weight: 600;
  font-size: 14px;
}
.shop_list_activ_t {
  display: inline-block;
  background: #1572e8;
  color: #FFFFFF;
  font-size: 11px;
  padding: 4px 15px;
  font-weight: 600;
  border-radius: 8px;
  margin: 0 0 0 15px;
}
.shop_list_attent_t {
  display: inline-block;
  background: #46a34e; 
  color: #FFF!important;
  font-size: 11px;
  padding: 4px 15px;
  font-weight: 600;
  border-radius: 8px;
  margin: 0 0 0 15px;
}
.prestaInfoComp .form-check, .form-group {
    margin-bottom: 0;
    padding: 10px 0 0 0!important;
}
.fileimg_t {
  width: 80px!important;
  height: 60px!important;
}
.img_and_name_file_t {
  margin: 0 0 1.3rem 1rem;
  text-overflow: ellipsis;
  width: 225px;
  white-space: nowrap;
  overflow: hidden !important;
}
.removeImgIcon {
  position: absolute;
  margin: -20px 0 0 -15px;
  z-index: 2px;
}
.name_file_t {
  font-family: "Maison Neue Bold";
  position: absolute;
  margin: 3.2rem 0 0 -5rem;
  font-size: 14px;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
}
.shop-uploadimg{
  border-bottom: 1px solid #dce1e4;
  border-top: 1px solid #dce1e4;
  padding: 10px 0;
  margin: 0 3rem 0 2rem!important;
}
.shop-uploadimg li{float: left;width: 33%;padding: 3px;}
.shop-uploadimg li .uploadimg-col{position: relative;height: 0;padding-bottom: 100%;}
.shop-uploadimg li .uploadimg-col::after{content: '';background: rgba(0, 0, 0, 0.4);position: absolute;left: 0;top: 0;width: 100%;height:100%;opacity: 0; transition: all 0.5s ease;}
.shop-uploadimg li .uploadimg-col img{position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: #eee;}
.uploadimg-col a{position: absolute;top: 0;right: 0;font-size: 20px;width: 30px;height: 30px;line-height: 30px;border-radius: 100%;text-align: center;color: #fff;z-index: 1;opacity: 0; transition: all 0.5s ease;}

.shop-uploadimg li:hover .uploadimg-col::after, .shop-uploadimg li:hover .uploadimg-col a{opacity: 1;}

#shopupload-imgList {
  margin: 0 0 0 -4rem;
}
.loading_dataTable {
  right: 50rem;
  margin: 30% 0 15rem 50%;
}
.offline_t {
  font-family: "Maison Neue Bold";
  display: inline-block;
  border-left: 3px solid #54a649;
  padding: .2rem 0 .2rem .5rem;
  font-size: 13px;
  color: #E91339;
  margin: .5rem 0 -25px 20%;
  /*position: absolute;*/
}

.desacClassName_t {
  font-family: "Maison Neue Bold";
  font-size: 16px;
  color: red;
  padding: 0 0 0 7%;
}

.actClassName_t {
  font-family: "Maison Neue Bold";
  font-size: 16px;
  color: #54A649;
  padding: 0 0 0 7%;
}

.modal_ville_t .offline_t {
  font-family: "Maison Neue Bold";
  display: inline-block;
  border-left: 3px solid #54a649;
  padding: .2rem 0 .2rem .5rem;
  font-size: 13px;
  color: #E91339;
  margin: .5rem 0 -25px 13%;
}
.imgSubMenu {
  width: 8%;
  margin: 0 8% 0 5%;
}

.offline_content_t {
  font-family: "Maison Neue Bold";
  display: inline-block;
  border-left: 3px solid #54a649;
  padding: .2rem 0 .2rem .5rem;
  font-size: 13px;
  color: #E91339;
  margin: -30% 0 0 10rem;
  position: absolute;
}
.label_select_t {
  font-size: 12px!important;
  color: #838383!important;
  font-weight: 400;
  opacity: .6;
}
.react-time-picker {
  width: 100%;
  margin: 0 0 0 2%;
}
.time_pick_t .label_select_t {
  color: #1E2431!important;
  opacity: .9;
  margin: .5rem 0 .5rem 2%;
}
.time_pick_t .react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #ebedf2;
  border-radius: 5px;

}
.time_pick_t .react-time-picker__inputGroup {
  text-align: center;
  padding: 13px!important;
}
.time_pick_t {
  padding: 15px 0 0 0;
}
.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
}
.picker_container {
  margin: 0 5px 20px!important;
}
.wrapper {
  min-height: 45px!important;
}
.modal_container {
  top: -200%!important;
}
.admin_p_r .react-tel-input .flag-dropdown {
  top: 10px!important;
  max-height: 42px!important;
}